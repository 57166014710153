






import { Component, Vue } from "vue-property-decorator";
import EditarPerfilComponent from "@/components/menu/EditarPerfilComponent.vue";

@Component({
  components: {
    EditarPerfilComponent
  }
})
export default class editarPerfilView extends Vue {}
