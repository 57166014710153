

































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import ApplicationForm from "@/components/Dashboard/ApplicationForm.vue";
import ApplicationFloatForm from "@/components/Dashboard/ApplicationFloatForm.vue";
import ApplicationsFloat from "@/components/Dashboard/ApplicationsFloat.vue";

interface Form {
  rut: string | null;
}

@Component({
  components: {
    ApplicationForm,
    ApplicationFloatForm,
    ApplicationsFloat
  }
})
export default class ApplicationsView extends Vue {
  private formData: Form = {
    rut: ""
  };
  private formattedRut: string | null = "";

  private loading = false;

  private data: any = null;

  private vNew = false;

  private vFloat = false;

  private titular = false;

  private rut: string | null = "";

  private selectTipoVecino = 0;

  private mounted() {
    if (
      this.$store.state.applicationRut &&
      this.$store.state.applicationRut != ""
    ) {
      this.rut = this.$store.state.applicationRut;
      this.formattedRut = this.rut;
      this.formData.rut = this.formattedRut;
      this.validate();
    } else if (
      localStorage.getItem("applicationRut") &&
      localStorage.getItem("applicationRut") != ""
    ) {
      this.rut = localStorage.getItem("applicationRut");
      this.formattedRut = this.rut;
      this.formData.rut = this.formattedRut;
      this.validate();
    }
  }

  private validate() {
    if (this.formData.rut) {
      this.$q.loading.show({
        delay: 100 // ms
      });
      this.loading = true;
      this.$axios
        .get("valid/rut?rut=" + this.formData.rut.toUpperCase())
        .then(res => {
          this.data = res.data;
          if (this.data.mensaje === "nuevo") {
            this.vNew = true;
            if (this.data.titular === "si") {
              this.titular = true;
              this.selectTipoVecino = 1;
            } else {
              this.titular = false;
              this.selectTipoVecino = 0;
            }
          } else {
            if (this.data.tipoVecino === 1) {
              this.vFloat = false;
              this.selectTipoVecino = this.data.tipoVecino;
              if (this.data.titular === "si") {
                this.titular = true;
              } else {
                this.titular = false;
              }
            } else {
              this.vFloat = true;
              this.selectTipoVecino = this.data.tipoVecino;
              if (this.data.titular === "si") {
                this.titular = true;
              } else {
                this.titular = false;
              }
            }
          }
          this.$q.loading.hide();
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$q.loading.hide();
          const data = err.response.data;
          let status = "";
          if (data.data === "bloqueado") {
            status = this.$t("status.blocked").toString();
          } else if (data.data === "pagado") {
            status = this.$t("status.payed").toString();
          } else if (data.data === "entregada") {
            status = this.$t("status.physic_card").toString();
          } else if (data.data === "en revision") {
            status = this.$t("status.review").toString();
          } else if (data.data === "por pagar") {
            status = this.$t("status.unpaid").toString();
          } else if (data.data === "corrección") {
            status = this.$t("status.correction").toString();
          } else if (data.data === "anulado") {
            status = this.$t("status.annulled").toString();
          } else if (data.data === "vencido") {
            status = this.$t("status.expired").toString();
          } else if (data.data === "para entrega") {
            status = this.$t("status.delivering").toString();
          } else if (data.data === "para renovar") {
            status = this.$t("status.renew").toString();
          } else if (data.data === "vigente") {
            status = this.$t("status.valid").toString();
          } else if (data.data === "no vigente") {
            status = this.$t("status.invalid").toString();
          } else if (data.data === "tarjeta anulada") {
            status = this.$t("status.c_annuled").toString();
          } else if (data.data === "tarjeta bloqueada") {
            status = this.$t("status.c_blocked").toString();
          }
          if (data.data === "bloqueado") {
            this.$swal({
              icon: "error",
              title: this.$t("notifications.titles.validate").toString(),
              text: this.$t(`notifications.error.RUT_BLOCKED`, {
                rut: this.formattedRut,
                status
              }).toString(),
              confirmButtonText: this.$t("buttons.accept").toString()
            });
          } else if (data.data === "creado") {
            this.$swal({
              icon: "error",
              title: this.$t("notifications.titles.validate").toString(),
              text: this.$t(`notifications.error.NEIGHBOR_EXIST`, {
                rut: this.formattedRut,
                status
              }).toString(),
              confirmButtonText: this.$t("buttons.accept").toString()
            });
          } else if (data.data === "anulado") {
            this.$swal({
              icon: "error",
              title: this.$t("notifications.titles.validate").toString(),
              text: this.$t(`notifications.error.CANCELED_REQUEST`, {
                rut: this.formattedRut,
                status
              }).toString(),
              confirmButtonText: this.$t("buttons.accept").toString()
            });
          } else {
            this.$swal({
              icon: "error",
              title: this.$t("notifications.titles.validate").toString(),
              text: this.$t(`notifications.error.${data.message}`, {
                rut: this.formattedRut,
                status
              }).toString(),
              confirmButtonText: this.$t("buttons.accept").toString()
            });
          }
        });
    }
  }

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "");
    this.formData.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
}
