













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DataCard extends Vue {
  @Prop({ required: true })
  private title!: string;

  @Prop({ required: true })
  private value!: string;
}
